import axios from "axios";
import React, { useState, useEffect } from "react";
import { setIsLoading } from "../../redux/aboutUs/aboutUsAction";
import { useMediaQuery } from "react-responsive";


const Table = ({ activeYear }) => {
  const [tableData, setTableData] = useState(null);
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  // const [serialNumber, setSerialNumber] = useState(0)

  const fallbackYear = new Date().getFullYear();

  

  useEffect(() => {
    const fetchTableData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `https://cms.nti.edu.ng/api/report/filter/${activeYear}`
        );
        const formattedData = formatData(response.data);
        setTableData(formattedData);
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTableData();
  }, [activeYear]);

  const formatData = (tableData) => {
    return tableData.map((data) => ({
      ...data,
      date: new Date(data.date).toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }),
    }));
  };
  return (
    <div style={{marginTop:'-30px'}}>
      {isPhone ? (
        <h1
          style={{
            marginBottom: 20,
            marginTop: 37,
            padding: 0,
            textAlign: "center",
            fontSize: 20,
          }}
        >{`${activeYear.name} Training programme`}</h1>
      ) : (
        <h1
          style={{ marginBottom: 20 }}
        >{''}</h1>
      )}
      {tableData && (
        <table style={{borderCollapse: 'collapse', width: '100%'}} >
          <thead>
            <tr className="table-row">
              <th className="table-header">SN</th>
              <th className="table-header">Date</th>
              <th className="table-header">Title Of Training</th>
              <th className="table-header">Description</th>
              <th className="table-header">Number of Participants</th>
              <th className="table-header">Download Reports</th>
            </tr>
          </thead>
          <tbody style={{backgroundColor: "#fff"}}>
            {tableData.map((data, index) => (
              <tr key={index}>
                <td className="table-data">{index + 1}</td>
                <td className="table-data">{data.date}</td>
                <td className="table-data">{data.title}</td>
                <td className="table-data">{data.description}</td>
                <td className="table-data">{data.participants}</td>
                <td className="table-data">
                  <button
                    style={{
                      background: "transparent",
                      padding: 10,
                      border: "1px solid #46B846",
                    }}
                    className="btn-table"
                  >
                    <a
                      href={`${data.file}`}
                      style={{ color: "#364A3D" }}
                      className="btn-table-link"
                    >
                      Download Report
                    </a>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Table;
