import React, { useEffect, useState } from "react";
import PageContainer from "../component/PageContainer";
import "../css/Home.css";
import Admission from "../assets/icon/home_admission_icon.svg";
import Programmes from "../assets/icon/home_programme_icon.svg";
import Portal from "../assets/icon/home_portal_icon.svg";
import { Link, useNavigate } from "react-router-dom";
import Arrow from "../assets/icon/home_arrow_icon.svg";
import ArrowWhite from "../assets/icon/arrow_white.svg";
import IMG4 from "../assets/images/img4.svg";
import Pic from "../assets/images/card_sample.svg";
import { ArrowForward, ArrowOutward } from "@mui/icons-material";
import { useMediaQuery } from "react-responsive";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { useSelector, useDispatch } from "react-redux";
import { Box, CircularProgress, Skeleton } from "@mui/material";
import { getNewsContent, setIsLoading } from "../redux/news/NewsAndMediaAction";
import { Helmet } from "react-helmet-async";
import moment from "moment";
import { news as theNews } from "../util/tempNews";
import { ProcessText, ReplaceSepcialChars } from "../util/escapeChar";

function Home() {
  const isPhone = useMediaQuery({ query: "(max-width: 900px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1023px)" });

  const navigate = useNavigate();

  const { isLoading, news } = useSelector((state) => state.news);

  const [loadingContact, setLoadingContact] = useState(false);

  const [contact, setContact] = useState("");
  const dispatch = useDispatch();

  const items = [
    {
      icon: Admission,
      text: "Admission",
      text2: "Apply for admissions to study at NTI",
      link: "https://my.nti.edu.ng/applicants/apply",
      linkText: "APPLY NOW",
    },
    {
      icon: Portal,
      text: "My NTI Portal",
      text2: "Login to the MY NTI Portal for current students",
      link: "http://my.nti.edu.ng",
      linkText: "LOGIN NOW",
    },

    {
      icon: Programmes,
      text: "Programmes",
      text2: "See our complete programmes and courses",
      link: "/programmes",
      linkText: "SEE MORE",
    },

    {
      icon: Admission,
      text: "NTI LMS",
      text2: "Apply for admissions to study at NTI",
      link: "https://learn.nti.edu.ng",
      linkText: "APPLY NOW",
    },
  ];

  const progCards = [
    {
      text: "PGDE",
      text2: "Post Graduate Diploma in Education",
      link: "/programmes/pgde",
    },
    {
      text: "B.ED",
      text2: "Bachelors Degree in Education",
      link: "/programmes/bed",
    },
    {
      text: "NCE",
      text2: "Nigeria Certificate in Education",
      link: "/programmes/nce",
    },
    {
      text: "ADE",
      text2: "Advance Diploma in Education",
      link: "/programmes/ade",
    },
    {
      text: "PTTP",
      text2: "Pivotal Teachers Training Programme",
      link: "/programmes/pttp",
    },
    {
      text: "CPD",
      text2: "Continuing Professional Development",
      link: "/programmes/cpd",
    },
  ];

  useEffect(() => {
    dispatch(getNewsContent());
  }, [dispatch]);

  const getContact = () => {
    setLoadingContact(true);
    fetch("https://cms.nti.edu.ng/api/contact-us")
      .then((res) => res.json())
      .then((res) => setContact(res?.pageContent))
      .catch((err) => console.log(err))
      .finally(() => setLoadingContact(false));
  };

  useEffect(() => {
    getContact();
  }, []);

  return (
    <PageContainer>
      <Helmet>
        <title>Home | National Teachers' Institute Kaduna</title>
      </Helmet>
      <AnimationOnScroll
        animatePreScroll={false}
        animateIn="animate__fadeInLeftBig"
        duration={3}
        animateOnce={true}
      >
        <section className="home_card_wrapper">
          {items.map((item, key) => (
            <div className="home_card" key={key}>
              <img src={item.icon} alt="icon" />
              <h4>{item.text}</h4>
              <p>{item.text2}</p>
              {item.link.includes("http") ? (
                <a
                  href={item.link}
                  className="home_card_link"
                  target={"_blank"}
                >
                  {item.linkText}
                  <img
                    src={Arrow}
                    alt="arrow"
                    style={{
                      marginLeft: "10px",
                    }}
                  />
                </a>
              ) : (
                <div
                  onClick={() => navigate(item.link)}
                  className="home_card_link"
                >
                  {item.linkText}
                  <img
                    src={Arrow}
                    alt="arrow"
                    style={{
                      marginLeft: "10px",
                    }}
                  />
                </div>
              )}
            </div>
          ))}
        </section>
      </AnimationOnScroll>

      {/* ////// ABOUT //////// */}
      <AnimationOnScroll
        animateOnce={true}
        duration={3}
        animatePreScroll={false}
        animateIn="animate__fadeInRightBig"
      >
        <section className="home_aboutUs_section_wrapper">
          <div className="home_aboutUs_section__img_wrapper">
            <img
              src={IMG4}
              alt="training"
              className="home_aboutUs_section_wrapper_img"
            />
          </div>
          {!isTablet && <div className="home_aboutUS_overlap"></div>}
          <div className="home_aboutUs_section">
            <h5 className="home_section_h5">ABOUT US</h5>
            <h4 className="home_section_h4">
              About the National Teachers’ Institute Kaduna Nigeria.
            </h4>
            <p>
              The National Teachers’ Institute was setup to serve as a Distant
              Learning Educational Institution.
            </p>
            <button onClick={() => navigate("/about-us")} className="home_btn">
              CONTINUE READING <img src={ArrowWhite} alt="" />
            </button>
          </div>
        </section>
      </AnimationOnScroll>
      {/* //////////// PROGRAMME ///////// */}

      <section className="home_program_section_wrapper">
        <AnimationOnScroll
          duration={2}
          animateOnce={true}
          animateIn="animate__fadeInLeftBig"
          className="home_program_section_animation"
        >
          <div className="home_program_section">
            <h5 className="home_section_h5">STUDY PROGRAMMES</h5>
            <h4 className="home_section_h4">Our Programmes</h4>
            <p
              style={{
                fontSize: "18px",
                textAlign: "justify",
              }}
            >
              Since inception, the Institute has mounted various programmes
              aimed at accelerating teacher development at all levels
              particularly at the basic education sector. This is with a view to
              raising the quality of education in the country in particular and
              the quality of overall national development in general.
            </p>
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll
          duration={2}
          animateOnce={true}
          animateIn="animate__fadeInRightBig"
          className="home_program_section_cards_animation"
        >
          <div className="home_program_section_cards">
            <div className="home_program_card">
              {progCards.slice(5).map((card, key) => (
                <div key={key} className="home_program_section_card_wrapper">
                  <div className="home_program_section_card">
                    <h4>{card.text}</h4>
                    <p>{card.text2}</p>
                    <Link
                      className="home_program_section_card_link"
                      to={card.link}
                    >
                      <p>Read More</p>
                      <ArrowForward className="arrow" />
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            <div className="home_program_card">
              {progCards.slice(3, 5).map((card, key) => (
                <div key={key} className="home_program_section_card_wrapper">
                  <div className="home_program_section_card">
                    <h4>{card.text}</h4>
                    <p>{card.text2}</p>
                    <Link
                      className="home_program_section_card_link"
                      to={card.link}
                    >
                      <p>Read More</p>
                      <ArrowForward className="arrow" />
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            <div className="home_program_card  home_program_card_third_card">
              {progCards.slice(0, 3).map((card, key) => (
                <div key={key} className="home_program_section_card_wrapper">
                  <div className="home_program_section_card">
                    <h4>{card.text}</h4>
                    <p>{card.text2}</p>
                    <Link
                      className="home_program_section_card_link"
                      to={card.link}
                    >
                      <p>Read More</p>
                      <ArrowForward className="arrow" />
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </AnimationOnScroll>
      </section>

      {/* ////// LINKS   ///////// */}
      <section className="home_link_section_wrapper">
        <h4 className="home_section_h4">Important Links</h4>
        <div className="home_link_section_btn_wrapper">
          <a
            href="https://ntilibraryrepository.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="home_btn"
          >
            NTI E-LIBRARY
          </a>

          <a
            href="http://opac.nti.edu.ng/"
            target="_blank"
            rel="noopener noreferrer"
            className="home_btn"
          >
            NTI E-CATALOGUE
          </a>
          <a
            href="http://elearn.nti.edu.ng/"
            target="_blank"
            rel="noopener noreferrer"
            className="home_btn"
          >
            NTI MOODLE SITE
          </a>
        </div>
      </section>

      {/* ///////// NEWS ////////// */}
      <AnimationOnScroll
        animateOnce={true}
        duration={3}
        animateIn="animate__fadeInLeftBig"
      >
        <section className="home_news_section">
          <h5 className="home_section_h5">MEDIA</h5>
          <div className="home_news_section_heading_wrapper">
            <h4 className="home_section_h4" style={{ marginBottom: "0px" }}>
              News and Updates
            </h4>
            <Link
              className="home_program_section_card_link read_more"
              to="/news"
              style={{ marginTop: "0px" }}
            >
              <p>Read More</p>
              <img
                src={Arrow}
                alt="arrow"
                style={{
                  marginLeft: "10px",
                }}
              />
            </Link>
          </div>

          {isLoading ? (
            <Box className="news_skeleton_wrapper">
              {Array.from(new Array(6)).map((num, key) => (
                <Box key={key}>
                  <Skeleton variant="rectangular" width={"100%"} height={218} />

                  <Box sx={{ pt: 0.5 }}>
                    <Box className="news_skeleton_sub_heading_wrapper">
                      <Skeleton /> <Skeleton />
                    </Box>
                    <Skeleton width="100%" />
                    <Skeleton width="100%" />
                    <Skeleton width="20%" />
                  </Box>
                </Box>
              ))}
            </Box>
          ) : (
            <div className="home_news_section_cards">
              {/* news.slice(0, 3) */}
              {news?.slice(0, 3).map((news, key) => (
                <div
                  key={key}
                  className="home_news_section_card"
                  onClick={() => {
                    navigate(`/news/${news?.id}`, {
                      news,
                    });
                  }}
                >
                  <img
                    src={`https://www.nti.edu.ng/static/media/Logo.b5d023f99a9bae47a2886b993915b4d9.svg`}
                    alt="students"
                    width={"60%"}
                    height={"320px"}
                  />
                  <div className="home_news_cat">
                    <p>{news?.category}</p>
                    <p>
                      {news?.created_at &&
                        moment(news?.created_at).format("Do MMMM  YYYY")}
                    </p>
                  </div>

                  <h4 className="home_section_h4">
                    {ReplaceSepcialChars(news?.title.substring(0, 50))}
                  </h4>
                  <p
                    className="home_section_news_p"
                    dangerouslySetInnerHTML={{
                      __html: news?.paragraph.substring(0, 100) + "..." || "",
                    }}
                  />
                  <Box
                    className=""
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <p>Read more</p>
                    <ArrowOutward
                      sx={{
                        color: "#4F9D6B",
                        width: 20,
                        height: 20,
                      }}
                    />
                  </Box>
                </div>
              ))}
            </div>
          )}
        </section>
      </AnimationOnScroll>

      {/* /////// INFO ////////// */}
      <section className="home_info_section">
        <div className="home_info_section_address_wrapper_wrapper">
          <div className="home_info_section_address_wrapper">
            <div className="home_address">
              <h5 className="home_section_h5">WANT QUICK INFO</h5>
              <h4 className="home_section_h4">Get in Touch With Us</h4>
              {/* <h4>
                <img src={Location} alt="location" /> Address
              </h4>
              <p>
                KM 5 Kaduna – Zaria Express Way, <br />
                Rigachikun, PMB 2191 Kaduna, Nigeria.
              </p> */}
            </div>
            <div
              className="contact"
              dangerouslySetInnerHTML={{ __html: contact }}
            ></div>
            {/* <div className="home_phone">
              <h4>
                <img src={Phone} alt="Phone" /> Phone Number
              </h4>
              <p>+234-(0)62-887873</p>
            </div>
            <div className="home_email">
              <h4>
                <img src={Email} alt="Email" /> Email Address
              </h4>
              <p>info@nti.edu.ng</p>
            </div> */}
            {/* <div className="home_email">
              <h4>
                <img src={Whatsapp} alt="Whatsapp" /> Whatsapp Number
              </h4>
              <p>09095450282</p>
            </div> */}
          </div>
          <div className="home_info_section_subscribe_wrapper">
            <h4>
              Subscribe to our News Letter to always <br /> get important
              Informations
              <br />
              regarding NTI
            </h4>
            <input type="text" placeholder="Email address" />
            <div className="home_checkbox">
              <input type="checkbox" />
              <p>
                I will love to receive newsletters and other updates from NTI,
                and I accept NTI Privacy Policy.
              </p>
            </div>
            <button className="home_btn" style={{ width: "100%" }}>
              SEND
            </button>
          </div>
        </div>
      </section>
    </PageContainer>
  );
}

export default Home;
