import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Grid, Typography, IconButton, Collapse, Button } from "@mui/material";
import { CaretLeft, CaretDown, CaretRight } from "@phosphor-icons/react";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import Table from "../component/Table/Table";
import PageContainer from "../component/PageContainer";
import { aboutUs, setIsLoading } from "../redux/aboutUs/aboutUsAction";

const Reports = () => {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const dispatch = useDispatch();

  const [departments, setDepartments] = useState([]);
  const [departmentYear, setDepartmentYear] = useState([]);
  const [departmentReport, setDepartmentReport] = useState([]);
  const [yearLoading, setYearLoading] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);

  const [years, setYears] = useState([]);
  const [activeYear, setActiveYear] = useState({ id: "7", name: "2024" });
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [openYears, setOpenYears] = useState({});

  useEffect(() => {
    dispatch(aboutUs());
    const getReportList = async () => {
      try {
        const response = await axios.get(
          `https://cms.nti.edu.ng/api/departments`
        );

        setDepartments(response.data);
        setYears(response.data);
      } catch (error) {
        console.log("Error fetching data", error);
      } finally {
        dispatch(setIsLoading(false));
      }
    };
    getReportList();
  }, [dispatch]);

  useEffect(() => {
    if (selectedDepartment) {
      const getDepartmentYears = async () => {
        setYearLoading(true);
        try {
          const responseyear = await axios.get(
            `https://cms.nti.edu.ng/api/report/year/department/${selectedDepartment.id}`
          );
          setDepartmentYear(responseyear.data);
          setIsLoading(false);
        } catch (error) {
          console.log("Error fetching department year data", error);
        } finally {
          setYearLoading(false);
        }
      };

      getDepartmentYears();
    }
  }, [selectedDepartment]);

  useEffect(() => {
    if (selectedDepartment) {
      const getDepartmentReports = async () => {
        setReportLoading(true);
        try {
          const responsereport = await axios.get(
            `https://cms.nti.edu.ng/api/report`
          );
          setDepartmentReport(responsereport.data);
          // setReportLoading(false);
        } catch (error) {
          console.log("Error fetching department year data", error);
        } finally {
          setReportLoading(false);
        }
      };

      getDepartmentReports();
    }
  }, [selectedDepartment]);

  const toggleYear = (year) => {
    setOpenYears((prev) => ({
      ...prev,
      [year]: !prev[year],
    }));
  };

  return (
    <PageContainer>
      <Helmet>
        <title>Reports | National Teachers' Institute Kaduna</title>
      </Helmet>

      <div id="document">
        <Typography variant="h4" align="center" style={{ paddingTop: 40 }}>
          NTI REPORT SHEETS
        </Typography>

        <div style={{ background: "#fff", marginTop: "30px" }}>
          <Grid
            container
            spacing={2}
            style={{ padding: isPhone ? "0 16px" : "0 92px" }}
          >
            {/* Sidebar */}
            <Grid item xs={12} md={3}>
              <div
                id="sidebar"
                style={{ paddingTop: "20px", paddingBottom: "20px" }}
              >
                {departments.length > 0 ? (
                  <div>
                    {departments.map((department, key) => (
                      <Typography
                        key={key}
                        variant="body1"
                        className={`listItems ${
                          selectedDepartment?.title === department.title
                            ? "listItems-active"
                            : ""
                        }`}
                        onClick={() => setSelectedDepartment(department)}
                        style={{
                          cursor: "pointer",
                          padding: "10px 0",
                          color:
                            selectedDepartment?.title === department.title
                              ? "green"
                              : "black",
                          fontWeight:
                            selectedDepartment?.title === department.title
                              ? "bold"
                              : "normal",
                        }}
                      >
                        {department.title} Department
                      </Typography>
                    ))}
                  </div>
                ) : (
                  <p style={{ color: "#000", textAlign: "center" }}>
                    No Available Department(s)
                  </p>
                )}
              </div>
            </Grid>

            {/* Main Content */}
            <Grid item xs={12} md={9}>
              {selectedDepartment && (
                <Typography
                  variant="h5"
                  style={{ fontWeight: "bold", marginBottom: "20px" }}
                >
                  {selectedDepartment.title} Department
                </Typography>
              )}

              {selectedDepartment && (
                <>
                  {yearLoading ? (
                    <p>Loading...</p>
                  ) : departmentYear.some((year) =>
                      departmentReport.some(
                        (report) =>
                          report.department_id === selectedDepartment.id
                      )
                    ) ? (
                    departmentYear
                      .filter((year) =>
                        departmentReport.some(
                          (report) =>
                            report.department_id === selectedDepartment.id
                        )
                      )
                      .map((year, key) => (
                        <div key={key} style={{ marginBottom: "20px" }}>
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                              cursor: "pointer",
                              padding: "10px",
                            }}
                            onClick={() => toggleYear(year.name)}
                          >
                            <div>
                              {openYears[year.name] ? (
                                <CaretDown
                                  size={32}
                                  color="#46C35F"
                                  className={`smooth-dropdown ${
                                    openYears[year.name] ? "open" : ""
                                  }`}
                                />
                              ) : (
                                <CaretRight
                                  size={32}
                                  color="#46C35F"
                                  className={`smooth-dropdown ${
                                    openYears[year.name] ? "open" : ""
                                  }`}
                                />
                              )}
                            </div>
                            <Typography
                              variant="body1"
                              style={{ fontWeight: "bold", fontSize: "20px" }}
                            >
                              {year.name}
                            </Typography>
                          </div>
                          <div
                            style={{ paddingLeft: "40px", marginTop: "20px" }}
                          >
                            <Collapse
                              in={openYears[year.name]}
                              timeout="auto"
                              unmountOnExit
                            >
                              {reportLoading ? (
                                <p>Loading</p>
                              ) : (
                                <Table activeYear={year.id} />
                              )}
                            </Collapse>
                          </div>
                        </div>
                      ))
                  ) : (
                    !yearLoading && (
                      <Typography
                        variant="body1"
                        style={{
                          marginTop: "20px",
                        }}
                      >
                        No available year
                      </Typography>
                    )
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </PageContainer>
  );
};

export default Reports;
